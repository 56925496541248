// ============================================
// Link
// ============================================
function link() {
	const url = location.href;
	const urlText = url.indexOf('?case_cat=purpose');
	if(urlText > 0){
		const tab01 = document.getElementById("tab01");
		const tab02 = document.getElementById("tab02");
		const contents = document.querySelectorAll(".js-tab__content-item");

		tab01.ariaSelected = false;
		tab01.setAttribute("tabindex", 1);
		tab02.ariaSelected = true;
		tab02.setAttribute("tabindex", 0);

		contents.forEach((item) => {
			if (item.id !== tab02.getAttribute("aria-controls")) {
				item.style.display = "none";
			} else {
				item.style.display = "revert";
			}
		});
	}
}

export { link };