// ============================================
// gsap scrollTrigger
// ============================================
import {gsap} from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

// ============================================
// 文字のフェード（右から左）
// ============================================
function textFade() {
  gsap.utils.toArray('.js-fade').forEach((target) => {
    gsap.to(target, {
      scrollTrigger: {
        trigger: target,
        start: () => 'top 75%',
        toggleClass: {
          targets: target,
          className: 'is-active',
        },
        once: true,
      },
    });
  });
  gsap.utils.toArray('.js-fade02').forEach((target) => {
    gsap.to(target, {
      scrollTrigger: {
        //markers: true,
        trigger: target,
        start: () => 'top 50%',
        toggleClass: {
          targets: target,
          className: 'is-active',
        },
        once: true,
      },
    });
  });
}


// ============================================
// 下から上
// ============================================
// function textFadeUp() {
//   gsap.utils.toArray('.js-fadeUp').forEach((target) => {
//     gsap.to(target, {
//       scrollTrigger: {
//         trigger: target,
//         start: () => 'top 75%',
//         toggleClass: {
//           targets: target,
//           className: 'is-active',
//         },
//         once: true,
//       },
//     });
//   });
// }

// ============================================
// export
// ============================================
export { textFade };