import '../scss/style.scss';

/**
 * @babel/polyfill の利用
 * ※ PromiseやArray.includes()など、構文ではない機能的な仕様を利用するために必要
 */
import '@babel/polyfill';


// ============================================
// モジュール読み込み
// ============================================
import { fixedHeader ,drawer, gNavToggle } from './lib/header';
import { ScrollHintFunc } from './lib/ScrollHint';
import { switchViewportFunc } from './lib/switchViewport';
//import { pageTop } from './lib/PageTop';
import { tabFunc } from './lib/Tab';
import { accordionFunc } from './lib/Accordion';
import { addAutoFunc,numKeyFunc } from './lib/Form';
//import { slider } from './lib/Slider';
import { textFade } from './lib/Scroll';
//import { HeadroomFunc } from './lib/Headroom';
import { link } from './lib/Link';


// ============================================
// 実行
// ============================================
window.addEventListener("DOMContentLoaded", () => {
	switchViewportFunc();
	fixedHeader();
	drawer();
	gNavToggle();
	ScrollHintFunc();
	//pageTop();
	tabFunc();
	accordionFunc();
	addAutoFunc();
	numKeyFunc();
	//HeadroomFunc();
	textFade();
	// textFadeUp();
	link();
});
