// ============================================
// ヘッダー追従
// ============================================

function fixedHeader() {
	const header = document.querySelector('.js-header');
	const hero = document.querySelector('.js-hero');
	if (hero) {
		let headerH = header.clientHeight;
		let heroH = hero.clientHeight;
		window.addEventListener('scroll', () => {
			let scrollH = window.scrollY;
			if (scrollH > heroH) {
				header.classList.add('is-active');
			} else {
				header.classList.remove('is-active');
				if (scrollH > headerH) {
					header.classList.add('is-hidden');
				} else {
					header.classList.remove('is-hidden');
				}
			}
		});
	}
}

// ============================================
// drawer
// ============================================

function drawer() {
	let opened = false
	const body = document.body;

	const header = document.querySelector(".js-header");
	const drawer = document.querySelector(".js-drawer");
	const hamburger = document.querySelector(".js-drawer-hamburger");
	const openClass = "is-open";
	init();

	function init() {
		if (drawer) {
			if (hamburger) {
				hamburger.addEventListener('click', (e) => {
					drawerClick(e);
				})
			}
		}
	}
	function drawerClick(e) {
		drawerToggleClass();
	}
	function drawerToggleClass() {
		if (!opened) {
			open();
		} else {
			close();
		}
	}

	//同ページ内でのクリック
	const currentPage = function () {
		const drawerItemLinks = document.querySelectorAll(".js-drawer a");
		drawerItemLinks.forEach((el) => {
			el.addEventListener('click', (e) => {
				close();
			})
		})
	}
	currentPage()

	//共通の動き
	// ==========================
	function open() {
		// this.disableBodyScroll(this.body, this.options);
		drawer.style.display = 'block';
		setTimeout(() => {
			drawer.setAttribute('aria-hidden', false);
			drawer.classList.add(openClass);
			header.classList.add(openClass);
			hamburger.setAttribute('aria-expanded', true);
			hamburger.setAttribute('aria-label', 'メニューを閉じる');

			opened = true;

      document.querySelector('#btn').textContent = 'close';

		}, 100);
	}

	function close() {
		drawer.setAttribute('aria-hidden', true);
		header.classList.remove(openClass);
		drawer.classList.remove(openClass);
		hamburger.setAttribute('aria-expanded', false);
		hamburger.setAttribute('aria-label', 'メニューを開く');

    document.querySelector('#btn').textContent = 'menu';

		opened = false;
		setTimeout(() => {
			drawer.style.display = 'none';
		}, 100)
	}
}

// ============================================
// PC時メガメニュー
// ============================================

function gNavToggle() {
	const trigger = document.querySelector('.js-gnav-toggle__trigger');
	const contents = document.querySelector('.js-gnav-toggle__contents');

	trigger.addEventListener('click', function(){
		// console.log('init');
		trigger.classList.toggle('is-active');
		contents.classList.toggle('is-active');
	});

}



// ============================================
// エクスポート
// ============================================
export { fixedHeader, drawer, gNavToggle };